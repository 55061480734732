<template>
  <section>
    <FetchLoading v-if="pending" />
    <FetchError v-else-if="error" :error="error" />
    <div v-else-if="courseArea">
      <Breadcrumbs :colour="courseAreaColour" :items="breadcrumbs" />
      <div>
        <LazyImg
          src="/images/ca.png"
          :data-src="secondaryImage"
          width="1600"
          height="354"
          class="w-full object-cover h-48 md:h-64 lg:h-auto"
          :alt="courseArea.secondaryImageAlt"
          style="max-height: 354px"
        />
      </div>

      <div class="container relative mt-8 flex">
        <!-- Sticky side nav -->
        <SideNav
          :colour="courseAreaColour"
          :course-area-name="courseArea.name"
          :nav-items="navItems"
          class="hidden md:block mb-4"
        />

        <!-- Page content -->
        <div class="w-full md:w-3/4">
          <h1
            class="h2 leading-relaxed inline border-b-5"
            :class="`border-brand-${courseAreaColour}-300`"
          >
            {{ courseArea.name }}
          </h1>
          <p class="h5 text-brand-grey-600 mt-4 md:hidden">
            {{ totalCourseCount }} {{ pluralize('courses', totalCourseCount) }}
          </p>

          <CourseTypeFilters
            v-if="viewport.isGreaterOrEquals('md')"
            :course-types="courseTypes()"
            :course-area-colour="courseAreaColour"
            class="hidden sm:hidden md:block"
            :selected-course-type="selectedCourseType"
            :total-course-count="totalCourseCount"
            @selected-course-type-changed="setSelectedCourseType"
          />
          <p class="mt-4 mb-8 md:mb-12 text-base">
            <template v-if="courseArea.description">
              {{ courseArea.description }}
            </template>
          </p>
          <!-- Mobile page nav -->
          <CourseAreaMobileNav
            :scroll-section="scrollSection"
            :nav-items="navItems"
            :colour="courseAreaColour"
            :course-area-name="courseArea.name"
            class="block md:hidden mb-4 z-20"
          />
          <CourseTypeFiltersMobile
            v-if="viewport.isLessThan('md')"
            :course-types="courseTypes()"
            :course-area-colour="courseAreaColour"
            class="md:hidden z-10"
            :selected-course-type="selectedCourseType"
            :total-course-count="totalCourseCount"
            @selected-course-type-changed="setSelectedCourseType"
          />
          <!-- add 16px (1rem) padding to offset -->
          <div
            v-scroll-spy="{
              offset: uiStore.appHeaderOffset + 16,
              data: 'scrollSection'
            }"
          >
            <section
              v-for="(group, i) in sortedSubjectGroups"
              :id="group.slug"
              :key="`${group}-${i}`"
              class="my-6 md:my-10 lg:my-12 flex flex-col"
            >
              <h3 class="h3 text-brand-grey-700">{{ group.name }}</h3>
              <p class="mt-3 mb-4 max-w-2xl text-xs sm:mt-4 sm:text-sm">
                <template v-if="group.description">
                  {{ group.description }}
                  <NuxtLink
                    :to="{
                      name: 'course-areas-courseArea-subjectGroup',
                      params: {
                        subjectGroup: group.slug,
                        courseArea: courseAreaSlug
                      }
                    }"
                  >
                    more...
                  </NuxtLink>
                </template>
              </p>

              <ul
                v-if="group.courses && group.courses.length"
                class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 grid-spacing"
              >
                <template
                  v-for="(course, index) in sortedCoursesByQualLevel(
                    group.courses
                  )"
                >
                  <li
                    v-if="course && course.id"
                    :key="`${course.id}-${course.title}-${index}`"
                  >
                    <CourseCard :course="course" :color="courseAreaColour" />
                  </li>
                </template>
              </ul>

              <ul v-else>
                <li>
                  <Card
                    class="p-4 shadow-none text-brand-grey-600 font-bold"
                    bg-color="bg-gray-200"
                    >There are no
                    {{
                      selectedCourseType &&
                      pluralize.singular(selectedCourseTypeTitle)
                    }}
                    courses currently being offered.</Card
                  >
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { courseTypeFilterMatch } from '~/utils/helpers'
import { useMainStore } from '~/stores/main'
import { useCourseAreaStore } from '~/stores/courseArea'
import { useUiStore } from '~/stores/ui'
import { CourseType } from '~/composables/useCourseTypeFilters'
import useFrontDoorApi from '~/composables/useFrontDoorApi'
import pluralize from 'pluralize'

import SubjectGroup = models.server.api.sitecore.courseArea.SubjectGroup
import Course = models.server.api.sitecore.courseArea.Course

interface NavItem {
  name: string
  id: string
}

const nuxtApp = useNuxtApp()
const viewport = useViewport()
const params = useRoute().params
const courseAreaSlug = params.courseArea as string
const { vScrollSpy } = useScrollSpy()

// Stores
const courseAreaStore = useCourseAreaStore()
const uiStore = useUiStore()
const mainStore = useMainStore()

// Composables.
const helpers = useHelpers()
const { getImgUrl } = useFrontDoorApi()
const { gtag } = useGtag()

const {
  pending,
  error,
  data: courseArea
} = await useAsyncData(() => courseAreaStore.fetchBySlug(courseAreaSlug))

if (error.value) {
  throw error.value
}

// setup meta for the page including SEO tags.
const title = `${courseArea.value?.name?.concat(' ') || ''}Courses | TAFE NSW`
const img = getImgUrl(helpers.getImgPath(courseArea.value?.primaryImage))

nuxtApp.runWithContext(() => {
  useSeoMeta({
    title,
    ogTitle: title,
    description: courseArea.value?.description || '',
    ogDescription: courseArea.value?.description || '',
    ogImage: img,
    ogImageSecureUrl: img,
    ogImageAlt: courseArea.value?.primaryImageAlt
  })
})

gtag('event', 'courseAreaSelect', {
  courseArea: courseArea.value?.name
})

const totalCourseCount = ref(0)
const selectedCourseType = ref<CourseType | null>(null)
const scrollSection = ref(0)

const primaryImage = computed(() => {
  const path = helpers.getImgPath(courseArea.value?.primaryImage)

  return getImgUrl(path)
})

const secondaryImage = computed(() => {
  const path = helpers.getImgPath(courseArea.value?.secondaryImage)

  return getImgUrl(path)
})

const courseAreaColour = computed(() => {
  return courseArea.value?.color || 'blue'
})

const breadcrumbs = computed(() => {
  return [
    {
      text: 'Course areas',
      to: { name: 'course-areas' }
    },
    {
      text: courseArea.value?.name,
      to: '/course-areas/' + courseAreaSlug
    }
  ]
})

const sortedSubjectGroups = computed(() => {
  const sortedSubjectGroups: SubjectGroup[] = JSON.parse(
    JSON.stringify(courseArea.value?.subjectGroups)
  ).sort((a: SubjectGroup, b: SubjectGroup) =>
    a.name && b.name ? a.name.localeCompare(b.name) : a.name ? -1 : 1
  )

  if (selectedCourseType.value?.title === 'Fee Free') {
    // special case: filter fee free courses
    sortedSubjectGroups.forEach((subjectGroup) => {
      subjectGroup.courses = subjectGroup.courses?.filter((c) => {
        return isFeeFree(c)
      })
    })
  } else if (selectedCourseType.value) {
    sortedSubjectGroups.forEach((subjectGroup) => {
      // pass all to filter?
      subjectGroup.courses = subjectGroup.courses?.filter((course) => {
        const type = course?.qualification?.type
        return type && courseTypeFilterMatch(selectedCourseType.value, type)
      })
    })
  }

  return sortedSubjectGroups
})

const navItems = computed(() => {
  return sortedSubjectGroups.value.reduce((acc: NavItem[], curr) => {
    if (curr.name) {
      acc.push({ name: curr.name, id: `#${curr.slug}` })
    }
    return acc
  }, [])
})

const selectedCourseTypeTitle = computed(() => {
  return (
    selectedCourseType.value?.title?.replace(/Courses/g, '')?.toLowerCase() ||
    ''
  )
})

function courseTypes(): [string, number][] {
  // Collect all the course types out of subject groups.
  let courses: Course[] = []
  let courseTypes: string[] = []
  if (courseArea.value?.subjectGroups) {
    courses =
      (courseArea.value?.subjectGroups
        .flatMap((sg) => sg.courses)
        .filter(Boolean) as Course[]) || []
    courseTypes =
      (courses
        .map((course) => course?.qualification?.type)
        .filter(Boolean) as string[]) || []
  }
  totalCourseCount.value = courseTypes.length

  // Count the number of courses per course type. i.e. 'Statement of Attainment', 'Diploma', 'Certificate IV', etc.
  interface CourseTypeCounts {
    [courseType: string]: number
  }
  const courseTypeCounts: CourseTypeCounts = courseTypes.reduce(
    (accumulator: CourseTypeCounts, currentType: string) => {
      if (!accumulator[currentType]) {
        accumulator[currentType] = 1
      } else {
        accumulator[currentType]++
      }
      return accumulator
    },
    Object.create(null)
  )

  // fee free count
  const feeFreeCount = courses.filter((c) => isFeeFree(c)).length

  // Return as an array of arrays. Format like: [ [ "Diploma", 3 ], ["Course", 10 ] ]
  return Object.entries({ ...courseTypeCounts, 'Fee Free': feeFreeCount })
}

function setSelectedCourseType(courseType: CourseType | null): void {
  selectedCourseType.value = courseType
}

function sortedCoursesByQualLevel(courses: Course[]): Course[] {
  return [...courses].sort(
    (courseA, courseB) =>
      (courseA.qualification?.level || Infinity) -
      (courseB.qualification?.level || Infinity)
  )
}

function isFeeFree(course: Course) {
  return !course.additionalInformation?.excludeFeeFree
}
</script>
